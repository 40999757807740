import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ViewAllReservations() {
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [csrfToken, setCsrfToken] = useState(''); // Ajout de l'état pour le token CSRF

    // Fonction pour gérer la modification (à personnaliser selon vos besoins)
    const handleModify = (id) => {
        console.log(`Modify reservation with id: ${id}`);
        // Ajoutez ici la logique pour modifier une réservation
    };

    // Fonction pour gérer la suppression (à personnaliser selon vos besoins)
    const handleDelete = (id) => {
        console.log(`Delete reservation with id: ${id}`);
        // Ajoutez ici la logique pour supprimer une réservation
        axios.delete(`https://www.dragonpong.xyz/api/reservations/${id}/`, {
            headers: {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            withCredentials: true
        })
        .then(response => {
            console.log('Reservation deleted:', response);
            // Mettre à jour l'état des réservations après la suppression
            setReservations(reservations.filter(reservation => reservation.id !== id));
        })
        .catch(error => {
            console.error('Error deleting reservation:', error);
        });
    };

    useEffect(() => {
        axios.defaults.withCredentials = true;

        // Récupérer le token CSRF
        axios.get('https://www.dragonpong.xyz/api/get-csrf-token/', {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        .then(response => {
            const token = response.data.csrfToken;
            setCsrfToken(token); // Stocker le token CSRF dans l'état

            // Effectuer la requête pour les réservations en incluant le token CSRF dans les en-têtes
            axios.get('https://www.dragonpong.xyz/api/reservations/', {
                headers: {
                    'X-CSRFToken': token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                withCredentials: true // Inclure les cookies dans la requête
            })
            .then(response => {
                setReservations(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching reservations:', error);
            });
        })
        .catch(error => {
            console.error('Error fetching CSRF token:', error);
        });
    }, []);

    if (loading) return <div>Loading...</div>;

    return (
        <div>
            <h1>All Reservations</h1>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {reservations.map(reservation => (
                        <tr key={reservation.id}>
                            <td>{reservation.id}</td>
                            <td>{reservation.type}</td>
                            <td>{reservation.start_date}</td>
                            <td>{reservation.end_date}</td>
                            <td>
                                <button onClick={() => handleModify(reservation.id)}>Modify</button>
                                <button onClick={() => handleDelete(reservation.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ViewAllReservations;
