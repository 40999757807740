import React, { useState } from 'react';
import './App.css';
import ViewAllReservations from './ViewAllReservations';
import CreateReservation from './CreateReservation'; // Assurez-vous que ce composant est correctement importé
import ModifyReservation from './ModifyReservation'; // Vous devez créer ce composant

function App() {
  const [view, setView] = useState('viewAll');

  return (
    <div className="App">
      <header className="App-header">
        <h1>Vue des Réservations</h1>
        <nav>
          <button onClick={() => setView('create')}>Créer une réservation</button>
          <button onClick={() => setView('modify')}>Modifier une réservation</button>
          <button onClick={() => setView('viewAll')}>Voir toutes les réservations</button>
        </nav>
      </header>
      <main>
        {view === 'viewAll' && <ViewAllReservations />}
        {view === 'create' && <CreateReservation />}
        {view === 'modify' && <ModifyReservation />}
      </main>
    </div>
  );
}

export default App;
