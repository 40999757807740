
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ModifyReservation({ reservationId }) {
    const [reservationData, setReservationData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://www.dragonpong.xyz/api/reservations/${reservationId}`)
            .then(response => {
                setReservationData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch reservation data:', error);
            });
    }, [reservationId]);

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`https://www.dragonpong.xyz/api/reservations/${reservationId}/modify`, reservationData)
            .then(response => {
                console.log('Reservation updated successfully:', response.data);
            })
            .catch(error => {
                console.error('Failed to update reservation:', error);
            });
    };

    if (loading) return <div>Loading...</div>;

    return (
        <form onSubmit={handleSubmit}>
            {/* Form fields for reservation data */}
            <button type="submit">Save Changes</button>
        </form>
    );
}

export default ModifyReservation;


// import React, { useState, useEffect } from 'react';
// import './ModifyReservation.css';
// import axios from 'axios';

// function ModifyReservation() {
//     axios.defaults.withCredentials = true;
//     const [reservations, setReservations] = useState([]);
//     const [filteredReservations, setFilteredReservations] = useState([]);
//     const [formData, setFormData] = useState({
//         reservation_number: '',
//         chambre: '',  // Assurez-vous que la valeur initiale n'est jamais undefined
//         lit: '',
//         start_Date: '',
//         end_Date: '',
//         comment: '',
//         facture: '',
//     });
//     const [message, setMessage] = useState('');
//     const [filterStates, setFilterStates] = useState({
//         aPayer: false,
//         payee: false,
//         annulee: false,
//         archivee: false,
//     });

//     function formatDate(dateString) {
//         const date = new Date(dateString);
//         const day = date.getDate().toString().padStart(2, '0');
//         const month = (date.getMonth() + 1).toString().padStart(2, '0');
//         const year = date.getFullYear();
//         return `${day}-${month}-${year}`;
//     }

//     useEffect(() => {
//         // Récupérer le token CSRF
//         axios.get('https://www.dragonpong.xyz/api/get-csrf-token/')
//             .then(response => {
//                 const csrfToken = response.data.csrfToken;
//                 // console.log("csrf dans modifi : ", csrfToken);

//                 // Effectuer la requête pour les réservations en incluant le token CSRF dans les en-têtes
//                 axios.get('https://www.dragonpong.xyz/api/reservations/', {
//                     headers: {
//                         'X-CSRFToken': csrfToken,
//                         'Content-Type': 'application/json'
//                     },
//                     withCredentials: true // Inclure les cookies dans la requête
//                 })
//                 .then(response => {
//                     setReservations(response.data);
//                     setFilteredReservations(response.data);
//                     // console.log("Reservations loaded:", response.data);
//                 })
//                 .catch(error => {
//                     console.error('Error fetching reservations:', error);
//                     setMessage('Failed to load reservations.');
//                 });
//             })
//             .catch(error => {
//                 console.error('Error fetching CSRF token:', error);
//             });
//     }, []);

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleSelectReservation = (reservation) => {
//         // Utiliser des chaînes vides comme valeurs par défaut si les données sont manquantes
//         setFormData({
//             reservation_number: reservation.reservation_number || '',
//             chambre: reservation.lit__chambre || '',
//             lit: reservation.lit__lit_number || '',
//             start_Date: reservation.start_date || '',
//             end_Date: reservation.end_date || '',
//             comment: reservation.comment || '',
//             facture: reservation.facture || '',
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//     //     API.post(`/reservations/${formData.id}`, formData)
//     //         .then(response => {
//     //             console.log('Reservation modified:', response.data);
//     //             setMessage('Reservation modified successfully!');
//     //         })
//     //         .catch(error => {
//     //             console.error('Failed to modify reservation:', error);
//     //             setMessage('Failed to modify the reservation.');
//     //         });
//     };

//     const handleFilterChange = (e) => {
//         setFilterStates({ ...filterStates, [e.target.name]: e.target.checked });
//     };

//     useEffect(() => {
//         const activeFilters = Object.keys(filterStates).filter(key => filterStates[key]);
//         const filtered = reservations.filter(reservation => activeFilters.includes(reservation.facture.replace(' ', '').toLowerCase()));
//         setFilteredReservations(filtered.length > 0 ? filtered : reservations);
//     }, [filterStates, reservations]);

//     return (
//         <div>
//             <form onSubmit={handleSubmit} className="reservation-form">
//                 <label>
//                     Réservation: {formData.reservation_number}
//                 </label>
//                 <label>
//                     Chambre:
//                     <input type="text" name="chambre" value={formData.chambre} onChange={handleChange} />
//                 </label>
//                 <label>
//                     Lit:
//                     <input type="text" name="lit__lit_number" value={formData.lit} onChange={handleChange} />
//                 </label>
//                 <label>
//                     Start Date:
//                     <input type="date" name="start_Date" value={formData.start_Date} onChange={handleChange} />
//                 </label>
//                 <label>
//                     End Date:
//                     <input type="date" name="end_Date" value={formData.end_Date} onChange={handleChange} />
//                 </label>
//                 <label>
//                     Commentaire:
//                     <input type="text" name="comment" value={formData.comment} onChange={handleChange} />
//                 </label>
//                 <label>
//                     État:
//                     <select type="text" name="facture" value={formData.facture} onChange={handleChange}>
//                         <option value="à payer">à payer</option>
//                         <option value="payée">payée</option>
//                         <option value="annulée">annulée</option>
//                         <option value="archivée">archivée</option>
//                     </select>
//                 </label>
//                 <button type="submit">Modify</button>
//                 {message && <p>{message}</p>}
//             </form>

//             <div className="filter-menu">
//                 <h3>Filter Reservations by State</h3>
//                 <label>
//                     <input type="checkbox" name="aPayer" checked={filterStates.aPayer} onChange={handleFilterChange} />
//                     à payer
//                 </label>
//                 <label>
//                     <input type="checkbox" name="payee" checked={filterStates.payee} onChange={handleFilterChange} />
//                     payée
//                 </label>
//                 <label>
//                     <input type="checkbox" name="annulee" checked={filterStates.annulee} onChange={handleFilterChange} />
//                     annulée
//                 </label>
//                 <label>
//                     <input type="checkbox" name="archivee" checked={filterStates.archivee} onChange={handleFilterChange} />
//                     archivée
//                 </label>
//             </div>

//             {filteredReservations.length > 0 ? (
//                 <table>
//                     <thead>
//                         <tr>
//                             <th scope="col">Réservation</th>
//                             <th scope="col">Chambre</th>
//                             <th scope="col">Lit</th>
//                             <th scope='col'>Du</th>
//                             <th scope='col'>au</th>
//                             <th scope='col'>commentaires</th>
//                             <th scope='col'>état</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {filteredReservations.map(reservation => (
//                             <tr key={reservation.reservation_number} onClick={() => handleSelectReservation(reservation)}>
//                                 <td>{reservation.reservation_number}</td>
//                                 <td>{reservation.lit__chambre}</td>
//                                 <td>{reservation.lit__lit_number}</td>
//                                 <td>{formatDate(reservation.start_date)}</td>
//                                 <td>{formatDate(reservation.end_date)}</td>
//                                 <td>{reservation.comment}</td>
//                                 <td>
//                                     {(() => {
//                                         switch (reservation.facture) {
//                                             case "à payer":
//                                                 return 'à payer';
//                                             case "payée":
//                                                 return 'payée';
//                                             case "annulée":
//                                                 return 'annulée';
//                                             case "archivée":
//                                                 return 'archivée'
//                                             default:
//                                                 return '';
//                                         }
//                                     })()}
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             ) : (
//                 <p>No reservations found.</p>
//             )}
//         </div>
//     );
// }

// export default ModifyReservation;
