import React, { useState, useEffect } from 'react';
import './CreateReservation.css';
import axios from 'axios';

function ReservationForm() {
    axios.defaults.withCredentials = true;
    const [csrfToken, setCsrfToken] = useState(null);
    const [formData, setFormData] = useState({
        lit__chambre: '',
        lit__lit_number: '',
        reservation_type: 'Chambre',
        startDate: '',
        endDate: '',
        reservation_number: '',
        comment: '',
    });
    const [chambres, setChambres] = useState([]);
    const [litsByChambre, setLitsByChambre] = useState({});
    const [availableLits, setAvailableLits] = useState([]);
    const [message, setMessage] = useState('');
    const [reservations, setReservations] = useState([]);
    const [last_reservation_number, setLastReservationNumber] = useState([]);

    // useEffect(() => {
    //     // Récupérer le token CSRF
    //     axios.get('https://www.dragonpong.xyz/api/get-csrf-token/')
    //         .then(response => {
    //             const token = response.data.csrfToken;
    //             setCsrfToken(token);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching CSRF token:', error);
    //         });
    // }, []);

    // useEffect(() => {
    //     // Récupérer les lits et les chambres
    //     axios.get('https://www.dragonpong.xyz/api/get-csrf-token/')
    //         .then(response => {
    //             const csrfToken = response.data.csrfToken;
    //             axios.get('https://www.dragonpong.xyz/api/lits/', {
    //                 headers: {
    //                     'X-CSRFToken': csrfToken,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 withCredentials: true
    //             })
    //                 .then(response => {
    //                     const uniqueChambres = new Set();
    //                     const litsMap = {};

    //                     response.data.forEach(item => {
    //                         uniqueChambres.add(item.chambre);
    //                         if (!litsMap[item.chambre]) {
    //                             litsMap[item.chambre] = [];
    //                         }
    //                         litsMap[item.chambre].push(item.lit_number);
    //                     });

    //                     setChambres([...uniqueChambres]);
    //                     setLitsByChambre(litsMap);
    //                 })
    //                 .catch(error => {
    //                     console.error('Failed to fetch chambres:', error);
    //                     setMessage('Failed to load chambres data.');
    //                 });
    //         })
    //         .catch(error => {
    //             console.error('Error fetching CSRF token:', error);
    //         });
    // }, []);

    // useEffect(() => {
    //     // Récupérer le numéro de la dernière reservation
    //     axios.get('https://www.dragonpong.xyz/api/get-csrf-token/')
    //         .then(response => {
    //             const csrfToken = response.data.csrfToken;
    //             axios.get('https://www.dragonpong.xyz/api/reservations/', {
    //                 headers: {
    //                     'X-CSRFToken': csrfToken,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 withCredentials: true
    //             })
    //                 .then(response => {
    //                     setReservations(response.data);
    //                     if (response.data.length > 0) {
    //                         setLastReservationNumber(parseInt((response.data[response.data.length - 1].reservation_number) / 100));
    //                     } else {
    //                         setLastReservationNumber(1);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     console.error('Error fetching reservations:', error);
    //                 });
    //         })
    //         .catch(error => {
    //             console.error('Error fetching CSRF token:', error);
    //         });
    // }, []);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const csrfResponse = await axios.get('https://www.dragonpong.xyz/api/get-csrf-token/');
                const token = csrfResponse.data.csrfToken;
                setCsrfToken(token);

                const [litsResponse, reservationsResponse] = await Promise.all([
                    axios.get('https://www.dragonpong.xyz/api/lits/', {
                        headers: { 'X-CSRFToken': token, 'Content-Type': 'application/json' },
                        withCredentials: true
                    }),
                    axios.get('https://www.dragonpong.xyz/api/reservations/', {
                        headers: { 'X-CSRFToken': token, 'Content-Type': 'application/json' },
                        withCredentials: true
                    })
                ]);

                const uniqueChambres = new Set();
                const litsMap = {};
                litsResponse.data.forEach(item => {
                    uniqueChambres.add(item.chambre);
                    litsMap[item.chambre] = [...(litsMap[item.chambre] || []), item.lit_number];
                });
                setChambres([...uniqueChambres]);
                setLitsByChambre(litsMap);

                setReservations(reservationsResponse.data);
                if (reservationsResponse.data.length > 0) {
                    setLastReservationNumber(parseInt((reservationsResponse.data[reservationsResponse.data.length - 1].reservation_number) / 100));
                }

                // Initialisation des lits disponibles pour la chambre par défaut si besoin
                if (formData.lit__chambre) {
                    setAvailableLits(litsMap[formData.lit__chambre]);
                }
            } catch (error) {
                console.error('Error initializing data:', error);
                setMessage('Failed to load initial data.');
            }
        };

        fetchInitialData();
    }, []);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [name]: value
    //     }));

    //     if (name === 'lit__chambre') {
    //         const litsForChambre = litsByChambre[value] || [];
    //         setAvailableLits(litsForChambre);
    //     }

    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));

        if (name === 'lit__chambre') {
            setAvailableLits(litsByChambre[value] || []);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Vérifier que la date de début est avant la date de fin
        if (new Date(formData.startDate) > new Date(formData.endDate)) {
            setMessage('La date de début doit être antérieure à la date de fin.');
            return;
        }

        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        const litsForChambre = litsByChambre[formData.lit__chambre] || [];

        if (formData.reservation_type === 'gestion_libre') {
            // Vérification de la disponibilité de tous les lits
            let allAvailable = true;
            for (const chambre in litsByChambre) {
                for (let lit of litsByChambre[chambre]) {
                    const isBooked = reservations.some(reservation => {
                        const resStart = new Date(reservation.start_date);
                        const resEnd = new Date(reservation.end_date);
                        return reservation.lit__lit_number === lit && (
                            (resStart <= endDate && resStart >= startDate) ||
                            (resEnd >= startDate && resEnd <= endDate) ||
                            (resStart <= startDate && resEnd >= endDate)
                        );
                    });
                    if (isBooked) {
                        setMessage(`Au moins un lit dans la chambre ${chambre} n'est pas disponible.`);
                        allAvailable = false;
                        break;
                    }
                }
                if (!allAvailable) break;
            }
            if (allAvailable) {
                try {
                    for (const chambre in litsByChambre) {
                        for (let lit of litsByChambre[chambre]) {
                            const reservationNumber = (last_reservation_number + 1) * 100 + parseInt(lit);
                            axios.post(`https://www.dragonpong.xyz/api/addreservation/`, {
                                lit__chambre: chambre,
                                lit__lit_number: lit,
                                start_date: formData.startDate,
                                end_date: formData.endDate,
                                reservation_number: reservationNumber,
                                comment: formData.comment,
                            }, {
                                headers: {
                                    'X-CSRFToken': csrfToken,
                                    'Content-Type': 'application/json'
                                },
                                withCredentials: true
                            });
                        }
                    }
                    setMessage('Toutes les réservations ont été créées avec succès pour la gestion libre.');
                } catch (error) {
                    console.error('Failed to create reservations:', error);
                    setMessage('Erreur lors de la création des réservations. Veuillez réessayer.');
                }
            }
        } else if (formData.reservation_type === 'Chambre') {
            // Vérification de la disponibilité de tous les lits dans la chambre sélectionnée
            let allAvailable = true;
            console.log("litsForChambre : ", litsForChambre)
            for (let lit of litsForChambre) {
                console.log("lit : ", lit)
                const isBooked = reservations.some(reservation => {
                    console.log("reservation : ", reservation)
                    const resStart = new Date(reservation.start_date);
                    const resEnd = new Date(reservation.end_date);
                    return reservation.lit__lit_number === lit && (
                        (resStart <= endDate && resStart >= startDate) ||
                        (resEnd >= startDate && resEnd <= endDate) ||
                        (resStart <= startDate && resEnd >= endDate)
                    );
                });
                if (isBooked) {
                    setMessage(`Au moins un lit dans la chambre ${formData.lit__chambre} n'est pas disponible.`);
                    allAvailable = false;
                    break;
                }
            }

            if (allAvailable) {
                // tous les lits sont dispos
                litsForChambre.forEach(lit => {
                    const reservationNumber = (last_reservation_number + 1) * 100 + parseInt(lit);
                    console.log("reservationNumber:", reservationNumber);
                    axios.get('https://www.dragonpong.xyz/api/get-csrf-token/')
                        .then(response => {
                            const csrfToken = response.data.csrfToken;
                            axios.post(`https://www.dragonpong.xyz/api/addreservation/`, {
                                lit__chambre: formData.lit__chambre,
                                lit__lit_number: lit,
                                start_date: formData.startDate,
                                end_date: formData.endDate,
                                reservation_number: reservationNumber,
                                comment: formData.comment,
                            }, {
                                headers: {
                                    'X-CSRFToken': csrfToken,
                                    'Referer': 'https://destannes.fr',
                                    'Content-Type': 'application/json'
                                },
                                withCredentials: true
                            })
                                .then(response => {
                                    console.log('Reservation formData', formData);
                                    console.log('Reservation created successfully for lit:', lit, response.data);
                                    setMessage('Reservation created successfully!');
                                })
                                .catch(error => {
                                    console.error('Failed to create reservation for lit:', lit, error);
                                    if (error.response && error.response.data && error.response.data.error) {
                                        setMessage(`Erreur : ${error.response.data.error}`);
                                    } else {
                                        setMessage('Erreur lors de la création de la réservation. Veuillez réessayer.');
                                    }
                                });
                        })
                        .catch(error => {
                            console.error('Error fetching CSRF token:', error);
                        });
                });
                setMessage('Toutes les réservations ont été créées avec succès.');
            }
        } else if (formData.reservation_type === 'Lit') {
            //verification de la dispo :
            const isBooked = reservations.some(reservation => {
                const resStart = new Date(reservation.start_date);
                const resEnd = new Date(reservation.end_date);
                return reservation.lit__lit_number === parseInt(formData.lit__lit_number) && (
                    (resStart <= endDate && resStart >= startDate) ||
                    (resEnd >= startDate && resEnd <= endDate) ||
                    (resStart <= startDate && resEnd >= endDate)
                );
            });

            if (isBooked) {
                setMessage('Ce lit est déjà réservé pour les dates sélectionnées. Veuillez choisir d’autres dates ou un autre lit.');
                return;
            }

            const reservationNumber = (last_reservation_number + 1) * 100 + parseInt(formData.lit__lit_number);
            axios.get('https://www.dragonpong.xyz/api/get-csrf-token/')
                .then(response => {
                    const csrfToken = response.data.csrfToken;
                    axios.post(`https://www.dragonpong.xyz/api/addreservation/`, {
                        lit__chambre: formData.lit__chambre,
                        lit__lit_number: formData.lit__lit_number,
                        start_date: formData.startDate,
                        end_date: formData.endDate,
                        reservation_number: reservationNumber,
                        comment: formData.comment,
                    }, {
                        headers: {
                            'X-CSRFToken': csrfToken,
                            'Referer': 'https://destannes.fr',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true
                    })
                        .then(response => {
                            console.log('Reservation formData', formData);
                            console.log('Reservation created successfully:', response.data);
                            setMessage('Reservation created successfully!');
                        })
                        .catch(error => {
                            if (error.response && error.response.data && error.response.data.error) {
                                setMessage(`Erreur : ${error.response.data.error}`);
                            } else {
                                setMessage('Erreur lors de la création de la réservation. Veuillez réessayer.');
                            }
                        });
                })
                .catch(error => {
                    console.error('Error fetching CSRF token:', error);
                });
        }
    };

    return (
        <form onSubmit={handleSubmit} className="reservation-form">
            <label>
                Type:
                <select name="reservation_type" value={formData.reservation_type} onChange={handleChange}>
                    <option value="Chambre">Chambre</option>
                    <option value="Lit">Lit</option>
                    <option value="gestion_libre">Gestion Libre</option>
                </select>
            </label>
            <select name="lit__chambre" value={formData.lit__chambre} onChange={handleChange}>
                {chambres.map((chambre, index) => (
                    <option key={index} value={chambre}>{`Chambre ${chambre}`}</option>
                ))}
            </select>
            {formData.reservation_type === 'Lit' && (
                <label>
                    Lit:
                    <select name="lit__lit_number" value={formData.lit__lit_number} onChange={handleChange}>
                        {availableLits.map((lit_number, index) => (
                            <option key={index} value={lit_number}>{`${lit_number}`}</option>
                        ))}
                    </select>
                </label>
            )}
            <label>
                Start Date:
                <input type="date" name="startDate" value={formData.startDate} onChange={handleChange} />
            </label>
            <label>
                End Date:
                <input type="date" name="endDate" value={formData.endDate} onChange={handleChange} />
            </label>
            <label>
                Commentaire (facultatif):
                <input type="text" name="comment" value={formData.comment} onChange={handleChange} />
            </label>
            <button type="submit">Submit</button>
            {message && <p>{message}</p>}
        </form>
    );
}

export default ReservationForm;
